<template>

    <div style="background-color: var(--white);">
        <br />
        <div style="color: var(--darkBackground); padding: 5px">
            <h4>COMPLAINTS AND DISPUTE RESOLUTION PROCEDURE</h4>
            <p>
                We try our best to deal with all users fairly at all times. If you have a complaint regarding our
                services, your first step should be to contact our Solutions as soon as reasonably practicable and give
                them details of your complaint. This will be escalated as necessary within our Solutions Team until it
                has been resolved.
            </p>
            <p>
                Solution Team - <a href="mailto:help@tucheze.com">help@tucheze.com</a>
            </p>
            <p>
                Head of Betting Operations - <a href="mailto:hello@tucheze.com">hello@tucheze.com</a>
            </p>
            <p>
                Solution Team Contact - <a href="tel:+254 716 797777">+254 716 797777</a>
            </p>
            <p>
                In any Email or Phone call to us, please include your phone number and full names and ensure that the
                phone number you provide is the same as the one that you registered your Tucheze.com Account with. Also,
                please include a clear explanation of your complaint.
            </p>
            <p>
                If our Solutions Team have escalated your complaint and it still cannot be resolved, you can ask for the
                dispute to be addressed by our Head of Betting Operations. We aim to resolve the matter to your
                satisfaction as soon as reasonably practicable and will contact you to discuss.
            </p>
            <p>
                If for some reason you are not satisfied with the resolution of the complaint by the company, you can
                complain to the Betting Control and Licensing Board (BCLB). Kindly note that by contacting BCLB you are
                confirming that you have not breached any of Tucheze.com Terms and Conditions.
            </p>
            <p>
                If you are not happy after, the parties shall refer the matter for Arbitration by a single arbitrator
                agreed by the parties where the chosen venue shall be Nairobi. Where there is no agreement each party
                shall appoint a single arbitrator. Where they are unable to agree the Chairman for the time being of the
                Kenyan Chapter of Chartered Institute or Arbitrators shall appoint one.
            </p>
            <p>
                Offensive or rude language as well as malicious or damaging comments will not be tolerated while
                contacting our staff or while discussing our products and services in any media, social network or
                forum. Any infringement of this policy will result in a suspension of the Account or in every additional
                action as may be required in order to ensure compliance.
            </p>
        </div>

        <bottom-navigation active-bets="0" page="login"></bottom-navigation>

    </div>

</template>

<script>

import BottomNavigation from "@/components/BottomNavigation";

export default {
    name: 'privacy',
    components: {

        BottomNavigation
    },
    mounted: function () {

        this.$store.dispatch("setCurrentPage", "responsible");
        this.reloadProfile();

    }

}
</script>